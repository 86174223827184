label.checkboxContainer {
  margin-bottom: 0;
  font-weight: 500;
  align-items: center;
  position: relative;
  display: flex;
}

.checkboxLabel {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: solid 2px rgba(0, 0, 0, 0.67);
  display: inline-block;
  background: transparent;
  cursor: pointer;
}

.checkboxChecked {
  background-image: url("../../assets/checkboxTick.png");
  background-size: cover;
  border: none;
}

.checkboxImage {
  height: 23px;
  width: 29px;
  position: absolute;
  left: 0;
  top: 8px;
  left: 4px;
  display: none;
  cursor: pointer;
}

.checkboxChecked ~ .checkboxImage {
  display: block;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
