#root .modal-lg {
  max-width: 985px;
}

.modal-content {
  margin-left: 20px;
  margin-right: 20px;
  padding: 32px;
}

.stdModalBody {
  /* max-width: 380px; */
  width: 100%;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-align: center;
}

.serviceModalContainer {
  margin: 20px 0 55px;
}

.serviceModalContainer p {
  text-align: left;
  font-size: 12px;
}

.serviceModalContainer h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.serviceModalContainer h4 {
  font-weight: bold;
  font-size: 14px;
}

.boldUnderline {
  font-weight: bold;
  text-decoration: underline;
}
