.formRow .react-select__control {
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.67);
  box-shadow: 0 4px 6px 0 #f5f5f5;
  background-color: #ffffff;
  font-size: 14px;
}

.formRow .react-select__single-value {
  color: black;
}

.formRow .react-select__control:hover {
  border: solid 1px rgba(0, 0, 0, 0.67);
}

.formRow .react-select__indicator-separator {
  display: none;
}

.formRow .react-select__indicator {
  color: rgba(0, 0, 0, 0.67);
}
