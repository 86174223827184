.referralBox {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 19px 86px;
  margin-bottom: 16px;
}

.referralBoxDetails {
  padding-right: 32px;
}

.readMore {
  text-decoration: underline;
  cursor: pointer;
}

.titleContainer {
  display: flex;
  padding: 0 13px;
  justify-content: space-between;
  align-items: center;
}

.referralBoxShowDetails {
  padding: 8px 15px;
  font-size: 12px;
  display: block;
  width: 132px;
  border: 2px solid #F4F4F5;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}

.titleContainer h2 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  padding-right: 20px;
  margin-bottom: 0;
  max-width: calc(100% - 280px);
}

.contentContainer {
  padding: 23px 13px 0px 13px;
  border-top: 1px solid rgb(244, 244, 245);
  margin-top: 18px;
}

.referralDowntick {
  margin-left: 6px;
  display: inline-block;
  transform: rotate(270deg);
  font-size: 16px;
  line-height: 12px;
  position: relative;
  top: 2px;
  transition: 300ms;
}

.downtickExpand {
  transform: rotate(90deg);
}

.contentContainer p {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.09px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 40px;
}

.contentContainer .referralBoxDetailsLabel {
  margin: 0 0 5px 0;
}

.contentContainer .referralBoxDetailsInfo {
  margin: 0 0 15px 0;
  font-weight: 600;
}

.contentContainer li {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.09px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
}

.contentContainer ul {
  padding-left: 16px;
}

.referralBoxNarrowView {
  padding: 19px;
  align-self: start;
}

.referralBoxNarrowView .titleContainer {
  flex-direction: column;
}

.referralBoxNarrowView .titleContainer > div {
  align-self: flex-end;
}


.referralBoxNarrowView .titleContainer h2 {
  max-width: 100%;
  width: 100%;
  margin-bottom: 10px;
  min-height: 38px;
}

@media (max-width: 550px) {
  .titleContainer {
    flex-direction: column;
  }

  .titleContainer h2 {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
