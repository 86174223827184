@import url("https://fonts.googleapis.com/css?family=Inter:400,500,600,700&display=swap");

#root {
  background-color: #f4f4f5;
  font-family: "Inter", sans-serif;
}

.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.home-feature-box .fa {
  font-size: 6rem;
}

.home-feature-box span {
  display: block;
  color: #111;
  font-weight: bold;
  margin-top: 1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
  border-bottom: 0;
}

.example-components-list li > a .fa {
  color: rgba(0, 0, 0, 0.35);
  float: right;
}

.btn-std {
  border-radius: 8px;
  background-color: #374e22;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: #ffffff;
  text-transform: none;
  padding: 13px 30px;
  min-width: 150px;
}

.btn-negative {
  background-color: gray;
}

.btn-std:hover {
  color: #ffffff;
}

.btn:disabled {
  opacity: 1;
  background-color: #a3a3a3;
  box-shadow: none;
}

h1 {
  width: 100%;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.15px;
  color: #263617;
  margin: 35px 0;
}

.adBaner {
  margin: 20px auto 0;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.tooltipStd {
  background-color: #0a84ff !important;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  padding: 0;
  font-size: 10px;
  text-transform: lowercase;
}

.underline {
  text-decoration: underline;
}

textarea {
  resize: vertical;
  height: 100px;
}

.fixed-top {
  z-index: 100;
}

.react-datepicker-wrapper {
  width: 100%;
}