@import url(https://fonts.googleapis.com/css?family=Inter:400,500,600,700&display=swap);
#root {
  background-color: #f4f4f5;
  font-family: "Inter", sans-serif;
}

.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.home-feature-box .fa {
  font-size: 6rem;
}

.home-feature-box span {
  display: block;
  color: #111;
  font-weight: bold;
  margin-top: 1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
  border-bottom: 0;
}

.example-components-list li > a .fa {
  color: rgba(0, 0, 0, 0.35);
  float: right;
}

.btn-std {
  border-radius: 8px;
  background-color: #374e22;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: #ffffff;
  text-transform: none;
  padding: 13px 30px;
  min-width: 150px;
}

.btn-negative {
  background-color: gray;
}

.btn-std:hover {
  color: #ffffff;
}

.btn:disabled {
  opacity: 1;
  background-color: #a3a3a3;
  box-shadow: none;
}

h1 {
  width: 100%;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.15px;
  color: #263617;
  margin: 35px 0;
}

.adBaner {
  margin: 20px auto 0;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.tooltipStd {
  background-color: #0a84ff !important;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  padding: 0;
  font-size: 10px;
  text-transform: lowercase;
}

.underline {
  text-decoration: underline;
}

textarea {
  resize: vertical;
  height: 100px;
}

.fixed-top {
  z-index: 100;
}

.react-datepicker-wrapper {
  width: 100%;
}
#root .modal-lg {
  max-width: 985px;
}

.modal-content {
  margin-left: 20px;
  margin-right: 20px;
  padding: 32px;
}

.stdModalBody {
  /* max-width: 380px; */
  width: 100%;
  margin: auto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-align: center;
}

.serviceModalContainer {
  margin: 20px 0 55px;
}

.serviceModalContainer p {
  text-align: left;
  font-size: 12px;
}

.serviceModalContainer h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.serviceModalContainer h4 {
  font-weight: bold;
  font-size: 14px;
}

.boldUnderline {
  font-weight: bold;
  text-decoration: underline;
}

.authenticationBoxContainer {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  max-width: 444px;
  border-radius: 8px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 30px;
  margin: 30px 0;
}

.authenticationLogo {
  margin-bottom: 30px;
  width: 140px;
  height: 59px;
}

.authenticationFormContainer {
  padding-top: 30px;
}

.inputContainer {
  margin-bottom: 25px;
  position: relative;
}

.inputContainer:first-of-type {
  margin-top: 25px;
}

.inputContainer label,
.inputContainer .label {
  display: block;
  margin-left: 15px;
  margin-bottom: 6px;
  font-size: 12px;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
}

.inputContainer .label {
  margin-bottom: 15px;
}

input:not([type="file"]),
input:not([type="checkbox"]),
textarea {
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.67);
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 #f5f5f5;
  width: 100%;
  font-size: 14px;
  display: block;
  padding: 8px 12px;
  padding-right: 39px;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.4;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.4;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.4;
}
input:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.4;
}

textarea {
  resize: vertical;
}

.authorizationAdditionalLinks {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.08px;
}

.authorizationAdditionalLinks a {
  color: rgba(0, 0, 0, 0.87);
}

.authorizationAdditionalLinks .lightText {
  color: rgba(0, 0, 0, 0.67);
}

.authenticationHeader {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.15px;
  color: #263617;
  margin-bottom: 0;
}

.authenticationHeaderAdditional {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 12px;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
}

.mainAuthenticationContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 16px;
}

.pageNotFound {
  width: 100%;
  text-align: center;
  margin-top: 80px;
  font-size: 32px;
}
.errorComponent {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.07px;
  text-align: right;
  color: #e73e34;
  position: absolute;
  padding: 0 12px;
  right: 0;
  bottom: -20px;
}

.exclamation {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  top: -29px;
}

.errorDiv {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.07px;
  color: #e73e34;
}

.successDiv {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.07px;
  color: #00C851;
}

.referralBox {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 19px 86px;
  margin-bottom: 16px;
}

.referralBoxDetails {
  padding-right: 32px;
}

.readMore {
  text-decoration: underline;
  cursor: pointer;
}

.titleContainer {
  display: flex;
  padding: 0 13px;
  justify-content: space-between;
  align-items: center;
}

.referralBoxShowDetails {
  padding: 8px 15px;
  font-size: 12px;
  display: block;
  width: 132px;
  border: 2px solid #F4F4F5;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
}

.titleContainer h2 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  padding-right: 20px;
  margin-bottom: 0;
  max-width: calc(100% - 280px);
}

.contentContainer {
  padding: 23px 13px 0px 13px;
  border-top: 1px solid rgb(244, 244, 245);
  margin-top: 18px;
}

.referralDowntick {
  margin-left: 6px;
  display: inline-block;
  transform: rotate(270deg);
  font-size: 16px;
  line-height: 12px;
  position: relative;
  top: 2px;
  transition: 300ms;
}

.downtickExpand {
  transform: rotate(90deg);
}

.contentContainer p {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.09px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 40px;
}

.contentContainer .referralBoxDetailsLabel {
  margin: 0 0 5px 0;
}

.contentContainer .referralBoxDetailsInfo {
  margin: 0 0 15px 0;
  font-weight: 600;
}

.contentContainer li {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.09px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 8px;
}

.contentContainer ul {
  padding-left: 16px;
}

.referralBoxNarrowView {
  padding: 19px;
  align-self: start;
}

.referralBoxNarrowView .titleContainer {
  flex-direction: column;
}

.referralBoxNarrowView .titleContainer > div {
  align-self: flex-end;
}


.referralBoxNarrowView .titleContainer h2 {
  max-width: 100%;
  width: 100%;
  margin-bottom: 10px;
  min-height: 38px;
}

@media (max-width: 550px) {
  .titleContainer {
    flex-direction: column;
  }

  .titleContainer h2 {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

.mainReferralContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.serviceTermSheetContainer h1,
.mainReferralDetailsContainer h1 {
  margin-top: 0px;
}

.serviceTermSheetContainer > p,
.mainReferralDetailsContainer > p {
  padding-top: 30px;
  margin-bottom: 3px;
}

.referralCol {
  width: calc(50% - 16px);
}

.referralCol:first-of-type {
  margin-right: 32px;
}

.serviceTermBox {
  background: white;
  padding: 22px 32px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.serviceTermBox > div {
  padding-left: 52px;
  padding-right: 52px;
}

.serviceTermUpper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f5;
  padding-bottom: 22px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.paymentTermAmountValue {
  font-weight: 500;
}

.serviceTermUpper > div {
  margin-bottom: 0;
}

.serviceTermLower {
  padding-top: 18px;
  display: flex;
}

.serviceTermLower ul {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
  margin-bottom: 0;
  padding-left: 15px;
}

.serviceTermTitle {
  width: 60%;
}

.serviceTermAmount {
  width: 28%;
  text-align: right;
}

.serviceTermAgreement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 12%;
  padding-left: 20px;
}

.serviceTermAgreement .checkboxContainer {
  margin-left: 10px;
}

.serviceTermHeader {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.15px;
  color: #263617;
  margin: 16px 0 24px;
}

.paymentTermTitle,
.serviceTermList {
  width: 80%;
}

.paymentTermAmount,
.paymentTermTotal {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.paymentTermAmountLabel,
.paymentTermAmountValue {
  display: inline-block;
}

.paymentTermAmountLabel {
  font-size: 12px;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
  width: 50%;
  text-align: right;
  padding-right: 10px;
}

.paymentTermAmountValue {
  width: 50%;
  text-align: right;
}

.paymentTermUpper {
  position: relative;
}

.paymentTermUpper:after {
  content: "";
  position: absolute;
  height: 1px;
  right: 52px;
  width: 20%;
  bottom: -1px;
  background-color: rgba(0, 0, 0, 0.67);
}

.serviceTermsAgreementContainer {
  width: 100%;
  max-width: 420px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 22px;
}

.serviceTermsAgreementContainer .checkboxContainer {
  margin-left: 10px;
}

.serviceTermSumUpBox {
  text-align: right;
  margin-bottom: 40px;
}

.valueDescription {
  font-size: 12px;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
  margin-right: 5px;
}

.newReferralColContainer {
  display: flex;
}

.newReferralHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changeViewBox {
  background: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
}

.stdView {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 30px;
  cursor: pointer;
}

.stdView span {
  display: block;
  width: 100%;
  height: 3px;
  background: #000000;
  margin: 3px 0;
}

.boxView {
  display: flex;
  width: 30px;
  flex-direction: column;
  cursor: pointer;
}

.boxView span {
  display: block;
  width: 8px;
  height: 3px;
  margin: 3px 0;
  background: #B5B9AF;
  position: relative;
}

.boxView span::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 3px;
  background: #B5B9AF;
  position: absolute;
  top: 0;
  left: 11px;
}

.boxView span::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 3px;
  background: #B5B9AF;
  position: absolute;
  top: 0;
  left: 22px;
}

.boxViewActive .stdView span {
  background: #B5B9AF; 
}

.boxViewActive .boxView span {
  background: #000000; 
}

.boxViewActive .boxView span::before {
  background: #000000; 
}

.boxViewActive .boxView span::after {
  background: #000000; 
}

.boxViewSectionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.boxViewSectionContainer > div {
  max-width: calc(33% - 10px);
}

@media (max-width: 1023px) {
  .serviceTermBox > div {
    padding-left: 0px;
    padding-right: 0px;
  } 

  .paymentTermUpper:after {
    right: 0;
  }
}

@media (max-width: 950px) {
  .referralBox {
    padding: 20px;
  }
}

@media (max-width: 850px) {
  .referralBox {
    padding: 20px;
  }

  .serviceTermTitle,
  .serviceTermList,
  .paymentTermTotal,
  .paymentTermTitle,
  .paymentTermAmount {
    width: 50%;
  }

  .serviceTermAmount {
    width: 35%;
  }

  .serviceTermAgreement {
    width: 15%;
  }
}

@media (max-width: 750px) {
  .referralCol {
    width: 100%;
  }

  .referralCol:first-of-type {
    margin-right: 0;
  }

  .newReferralColContainer {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .serviceTermUpper {
    flex-wrap: wrap;
  }

  div.serviceTermTitle {
    width: 100%;
    margin-bottom: 10px;
  }

  .serviceTermAmount {
    width: calc(100% - 110px);
  }

  .serviceTermAgreement {
    width: 110px;
  }

  .paymentTermTitle,
  .paymentTermAmount,
  .serviceTermList,
  .paymentTermTotal {
    width: 100%;
  }

  .paymentTermAmount {
    margin-top: 10px;
  }

  .serviceTermLower {
    flex-direction: column-reverse;
  }

  .serviceTermList {
    margin-top: 15px;
  }
}




label.checkboxContainer {
  margin-bottom: 0;
  font-weight: 500;
  align-items: center;
  position: relative;
  display: flex;
}

.checkboxLabel {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: solid 2px rgba(0, 0, 0, 0.67);
  display: inline-block;
  background: transparent;
  cursor: pointer;
}

.checkboxChecked {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAKKADAAQAAAABAAAAKAAAAAB65masAAACqklEQVRYCe2Yy2oUQRSGncSYgEPIyoVxFaIus/EBvESNGqKoKKIiPoE73Ygirly6VoIhESWK4gtERCQqiiZEUFSMd4i3eAU3g99vZkJZ3dWp7kn3tDAHvu46py7nn1M93T1TWDBjSzjtgi5ohQaohZVI+g3GYBimChwk7hQUIU/2AzFHGjkchBV5UlbWsohzUVupbc2rdS1Ema450/bh/DYDGbabyTVk5GtVBe0vRK3ESZedu8EWZ4jPR7MusNp9qFfQqKDua5dhsxGbs5lVBZtQcgl2wBXoAS/LQqDE6bnaV1ake91V2FD2I09pC9SD4CJss1S04F+DbisecNMUKHEXYHsg60xAIg85+mbDaQnUS8h52DmbKdi4Tmh3MPxvJA2BEjcIer902Q06euGXa0AlHlfgSiYerkwOOWu9AdgT0lcJ3aSxBeYUpwm6TnxtOQNHYCkshuNgmsSdg71m0Grfwtd98KcVd7q+FexkBV0zEic7Vuavw0Hr9MP+SiDkPEpsE+hN2dt8BHawmsS1W6uewD8KBTgLB8Blt+noge+uAa64zxarMsscC5wkvhVWOfoVvgsbQT+GYptPBVWZyYiVo8TdY56eGInEKaePwJeMWwOvNCGG3WfsevgaY05gqI9ATZqE1fAafOwBgyRu2mdw1BhfgVrjBaiSb+RE2EP6uuFLxBjvrjgCtehzkMi3ckJsnJjEfQ7pSxSKK1BJnoFEvpNj2ATtdfDJiFXdTCJQSZ/CWngvB3sE8j/KmU9LKlAanoBE6Sau8weYd/O5UUclfUynxKVm1VQwNVHmwnWBZjWStP+LCpasT9Zs+Vm6du5SgexnoC1LFTFyTWuL9Yd1Xm1MAvWrP9ZreEafRpqGGznoB8woFEFbrT95tPW1MH0f9P54B07D1B/hgF4UkS5prgAAAABJRU5ErkJggg==);
  background-size: cover;
  border: none;
}

.checkboxImage {
  height: 23px;
  width: 29px;
  position: absolute;
  left: 0;
  top: 8px;
  left: 4px;
  display: none;
  cursor: pointer;
}

.checkboxChecked ~ .checkboxImage {
  display: block;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.draftReferralContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 16px;
}

.draftReferralRow {
  display: flex;
  background-color: white;
  margin-bottom: 15px;
  border-radius: 8px;
}

.draftReferralRow > div {
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1.2;
}

.draftReferralDeleteIcon {
  height: 18px;
  width: 58px;
  cursor: pointer;
}

.draftReferralEditButton,
.draftReferralDelete {
  padding: 20px 30px;
}

.draftReferralStdRow .draftReferralName {
  font-weight: 500;
}

.draftReferralMarginContainer {
  margin-top: 25px;
}

.draftReferralName,
.draftReferralService,
.draftReferralServiceProvider,
.draftReferralEditDate {
  padding: 20px 20px 20px 0;
  font-size: 14px;
  letter-spacing: 0.09px;
  color: rgba(0, 0, 0, 0.87);
}

.draftReferralName,
.draftReferralServiceProvider {
  width: calc(33% - 70px);
}

.draftReferralService {
  width: calc(18% - 70px);
}

.draftReferralEditDate {
  width: calc(16% - 70px);
}

.draftReferralName {
  padding-left: 75px;
}

.draftReferralHead {
  background: transparent;
}

.draftReferralHead > div {
  color: rgba(0, 0, 0, 0.67);
}

div.draftReferralMobileLabel {
  display: none;
  width: 50%;
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .draftReferralName {
    padding: 15px;
  }
  .draftReferralEditButton, .draftReferralDelete {
    padding: 15px;
  }
  .draftReferralEditDate {
    width: 15%;
  }
}

@media (max-width: 750px) {
  .draftReferralName {
    width: 25%;
  }
  .draftReferralService {
    width: 15%;
  }
  .draftReferralServiceProvider {
    width: 35%;
  }
  .draftReferralEditDate {
    width: 25%;
  }

  .draftReferralRow {
    flex-wrap: wrap;
    justify-content: center;
  }

  .draftReferralHead .draftReferralEditButton,
  .draftReferralHead .draftReferralDelete {
    display: none;
  }
}

@media (max-width: 500px) {

  .draftReferralHead {
    display: none;
  }

  .draftReferralName,
  .draftReferralServiceProvider,
  .draftReferralService,
  .draftReferralEditDate,
  .draftReferralEditButton,
  .draftReferralDelete {
    width: 50%;
    padding: 15px;
  }

  div.draftReferralMobileLabel {
    display: block;
  }

  .draftReferralEditButton,
  .draftReferralDelete {
    justify-content: center;
  }

  .draftReferralMarginContainer {
    margin-top: 0;
  }
}
.dashboardReferralContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dashboardRow {
  display: flex;
  background-color: white;
  border-radius: 8px;
} 

.dashboardMarginContainer {
  margin-top: 25px;
}

.dashboardRow > div {
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1.2;
}

.stdDashboardRowContainer {
  margin-bottom: 15px;
  display: flex;
  background-color: white;
  border-radius: 8px;
  flex-direction: column;
}

.dashboardStdRow {
  position: relative;
  cursor: pointer;
}

.dashboardStdWidth,
.dashboardAmountDue {
  justify-content: center;
  text-align: center;
}

.dashboardName,
.dashboardStdWidth,
.dashboardAmountDue {
  padding: 20px 10px 20px 10px;
  font-size: 14px;
  letter-spacing: 0.09px;
  color: rgba(0, 0, 0, 0.87);
}

.dashboardStdRow > div {
  padding-top: 50px;
  padding-bottom: 50px;
}

.dashboardStdRow .dashboardName {
  font-weight: 500;
}

.dashboardName {
  width: 10%;
}

.dashboardAmountDue {
  width: 8%;
}

.dashboardButtonMore {
  width: 8%;
}

.dashboardShowDetailsButton {
  min-width: unset;
  max-width: 100%;
  padding: 6px;
  margin: 0 auto;
}

.dashboardAmountDue {
  padding-right: 25px;
}

.dashboardStdWidth {
  width: 12%;
}

.dashboardName {
  padding-left: 45px;
}

.dashboardHead {
  background: transparent;
  margin-bottom: 15px;
}

.dashboardHead > div {
  color: rgba(0, 0, 0, 0.67);
}

.dashboardTooltip {
  position: absolute !important;
  left: calc(50% - 14px);
  top: -15px;
}

.dashboardAmountTooltip {
  left: calc(50% - 46px);
}

.progressIcon {
  background-color: #374e22;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  height: 16px;
  width: 16px;
  position: absolute;
  border-radius: 50%;
  z-index: 2;
}

.progressIcon::after {
  content: "\002713"
  
}

.inProgress {
  background-color: rgba(55, 78, 34, 0.67);
  padding-bottom: 6px;
}

.inProgress::after {
  content: "."
}

.pending {
  background-color: #c1c1c1;
  padding-bottom: 6px;
}

.pending::after {
  content: "..."
}

.dashboardProgressContainer::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  left: 50%;
  background-color: #747480;
  opacity: 0.2;
  display: block;
}

.dashboardGradientContainer::after {
  content: "";
  position: absolute;
  height: 15px;
  left: -50%;
  width: 100%;
  top: calc(50% - 7px);
  display: block;
}

.completedContainer::after {
  opacity: 0.41;
  background-color: #374e22;
}

.inProgressContainer::after {
  opacity: 0.41;
  background-image: linear-gradient(to left, rgba(163, 163, 163, 0.35), #374e22);
}

.pendingContainer::after {
  opacity: 0.41;
  background-color: rgba(163, 163, 163, 0.37);
}

.dashboardAdditionalInfo {
  display: none;
  padding: 14px 0;
  margin: 0px 75px 30px;
  border-top: 1px solid #e4e4e6;
}

.dashboardAdditionalInfo.showAdditionalInfo {
  display: flex;
}

.dashboardAdditionalInfo {
  font-size: 14px;
  position: relative;
}

.dashboardAdditionalInfo p {
  margin-bottom: 0px;
}

.dashboardAdditionalInfo .dashboardAdditionalValue {
  margin-bottom: 24px;
  font-weight: 500;
}

.dashboardAdditionalValue a {
  color: rgba(0, 0, 0, 0.87);
}

.dashboardAdditionalStd {
  width: 25%;
  padding-right: 10px;
}

.dashboardFilesContainer {
  width: 50%;
}

.dashboardFile {
  font-weight: 500px;
  width: 50%;
  display: inline-block;
  margin-bottom: 18px;
  font-weight: 500;
  cursor: pointer;
}

.dashboardFile {
  text-decoration: underline;
  color: #0000EE;
}

.dashboardFile:hover {
  color: #000000;
}

.fileIcon {
  width: 18px;
  height: 10px;
  margin-right: 7px;
}

.dahsboardHideDetailsContainer {
  padding: 8px;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 139px;
  height: 30px;
  border-radius: 8px;
  border: solid 2px #f4f4f5;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  right: 0;
  bottom: -31px;
  border-bottom: none;
  color: rgba(0, 0, 0, 0.87);
}

.upTickIcon {
  width: 12px;
  height: 8px;
  margin-left: 8px;
}

.dashboardLegend {
  margin: 30px 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  letter-spacing: 0.08px;
  color: rgba(0, 0, 0, 0.67);
}

.dashboardLegend .progressIcon {
  position: relative;
  margin-right: 15px;
}

.dashboardLegend > div {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  width: 100px;
}

div.dashboardMobileLabel {
  display: none;
}

.dashboardH1 {
  display: flex;
  align-items: center;
}

.newStatuses {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  width: 111px;
  height: 22px;
  border-radius: 12px;
  background-color: #0a84ff;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: -0.1px;
  color: #ffffff;
}

.newStatusesSmall {
  text-transform: uppercase;
  width: 40px;
  position: absolute;
  margin: 0;
  top: -35px;
}

.newDot {
  display: inline-block;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #0a84ff;
}

@media (max-width: 950px) {
  .dashboardName {
    padding-left: 20px;
  }

  .dashboardHead {
    display: none;
  }

  .dashboardMarginContainer {
    margin-top: 0;
  }

  .dashboardRow {
    flex-wrap: wrap;
  }

  div.dashboardMobileLabel {
    display: block;
  }

  .dashboardStdRow {
    padding: 0 60px;
  }

  div.dashboardName,
  div.dashboardAmountDue,
  div.dashboardStdWidth,
  div.dashboardMobileLabel {
    padding: 20px;
  }
  
  div.dashboardMobileLabel {
    width: calc(100% - 200px);
    font-size: 14px;
    font-weight: bold;
  }

  div.dashboardName,
  div.dashboardAmountDue,
  div.dashboardButtonMore,
  div.dashboardStdWidth {
    justify-content: center;
    width: 200px;
  }

  .dashboardProgressContainer::before {
    width: 0;
  }

  .inProgressContainer::after {
    opacity: 0.41;
    background-image: linear-gradient(to top, rgba(163, 163, 163, 0.35), #374e22);
  }  

  .dashboardGradientContainer::after {
    content: "";
    position: absolute;
    height: 100%;
    left: calc(50% - 7px);
    top: calc(-45% - 7px);
    display: block;
    width: 15px;
  }

  .dashboardAdditionalStd {
    width: 50%;
  }

  .dashboardFilesContainer {
    width: 100%;
  }

  .dashboardAdditionalInfo.showAdditionalInfo {
    flex-wrap: wrap;
  }
}

@media (max-width: 650px) {
  .dashboardAdditionalStd,
  .dashboardFile {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .dashboardStdRow {
    padding: 0;
  }

  .dashboardAdditionalInfo.showAdditionalInfo {
    margin: 0 20px 30px;
  }

  div.dashboardMobileLabel {
    width: calc(100% - 120px);
  }

  div.dashboardName,
  div.dashboardAmountDue,
  div.dashboardButtonMore,
  div.dashboardStdWidth {
    justify-content: center;
    width: 120px;
  }
}
.formRow .react-select__control {
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.67);
  box-shadow: 0 4px 6px 0 #f5f5f5;
  background-color: #ffffff;
  font-size: 14px;
}

.formRow .react-select__single-value {
  color: black;
}

.formRow .react-select__control:hover {
  border: solid 1px rgba(0, 0, 0, 0.67);
}

.formRow .react-select__indicator-separator {
  display: none;
}

.formRow .react-select__indicator {
  color: rgba(0, 0, 0, 0.67);
}


.formRow .checkboxContainer {
	margin-left: 30px;
	margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
	font-weight: 300;
}

.formRow .checkboxContainer .checkboxLabel {
	min-width: 20px;
	cursor: pointer;
	position: relative;
	margin-right: 20px;
	align-self: flex-start;
}
label.radioContainer {
  margin-bottom: 10px;
  font-weight: 500;
  align-items: center;
  position: relative;
  display: flex;
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 300;
}

.radioLabel {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 100%;
  border: solid 2px rgba(0, 0, 0, 0.67);
  display: inline-block;
  background: transparent;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  align-self: flex-start;
}

.radioChecked:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.67);
}

.radioImage {
  height: 23px;
  width: 29px;
  position: absolute;
  left: 0;
  top: 8px;
  left: 4px;
  display: none;
  cursor: pointer;
}

.radioChecked ~ .radioImage {
  display: block;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.radioContainer b {
  font-weight: 700;
}
.mainFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.mainFormContainer h1 {
  margin-top: 0px;
  padding-right: 250px;
}

.mainFormContainer > p {
  padding-top: 30px;
  margin-bottom: 3px;
  padding-right: 250px;
}

.mainFormContainer .lastUpdate {
  position: absolute;
  right: 0;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.08px;
  text-align: right;
  color: rgba(0, 0, 0, 0.67);
  top: 46px;
}

.formUpdateBullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #0a84ff;
  margin-right: 10px;
}

.formBoxContainer {
  border-radius: 8px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-bottom: 74px;
  padding: 26px 72px;
}

.formBoxContainer h2 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 30px;
}

.formBoxContainer h2:not(:first-of-type) {
  margin-top: 30px;
}

.formRow {
  display: flex;
  justify-content: space-between;
}

.formCol {
  width: calc(50% - 15px);
  display: flex;
  justify-content: space-between;
}

.formCol .inputContainer {
  margin: 0 0 30px 0;
  width: calc(50% - 15px);
}

.formCol .inputContainer.inputWideContainer {
  width: 100%;
}

.dragAndDrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.67);
  border-style: dashed;
  margin-bottom: 55px;
  width: 100%;
  max-width: 550px;
  position: relative;
}

.dragAndDrop > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 100%;
  max-width: 550px;
}

.dragAndDrop .formUploadedFilesContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: solid 1px rgba(0, 0, 0, 0.67);
  border-top-style: dashed;
}

.formUploadedFilesContainer p {
  margin-bottom: 0;
  font-size: 14px;
}

.dragAndDrop img {
  height: 22px;
  width: 32px;
  margin-bottom: 10px;
}

.uploadingFile {
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background-color: lightgray;
}

.inputFileButton {
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  max-width: 250px;
  border-radius: 17px;
  border: solid 1px rgba(0, 0, 0, 0.67);
  padding: 11px;
}

.dragAndDrop .dropInfo {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.37);
  margin-bottom: 0;
}

.fileList {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 12px;
}

.inputContainer.additionalContainer {
  margin-left: 68px;
  width: 100%;
  max-width: 480px;
}

.inputContainer.additionalContainerNoLm {
  margin-left: 0;
}

.inputContainer.fullWidthInputContainer {
  width: 100%;
}

.submitFormContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
}

.requiredInfo {
  font-size: 12px;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
}

.submitFormContainer .btn {
  min-width: 250px;
  padding: 10px 30px;
}

.medFormColQuestions {
  flex-direction: column;
  width: 100%;
}

.medFormColQuestions .inputWideContainer{
  margin-bottom: 4px;
} 

.deleteFile {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  transform: rotate(45deg);
  font-size: 18px;
  color: #DE473E;
  cursor: pointer;
  position: relative;
  top: 1px;
}

@media (max-width: 1023px) {
  .formRow {
    flex-wrap: wrap;
  }

  .formCol {
    width: 100%;
  }
}

@media (max-width: 700px) {

  .formCol {
    flex-wrap: wrap;
  }

  .formCol .inputContainer {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .formBoxContainer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .formBoxContainer {
    position: relative;
  }

  .submitFormContainer {
    flex-direction: column;
  }

  .mainFormContainer h1 {
    padding-right: 0;
  }

  .mainFormContainer .lastUpdate {
    top: -25px;
    right: 10px;
  }

  .requiredInfo {
    position: relative;
    top: -15px;
  }
}
.navbarContainer {
  width: 100%;
  max-width: 1312px;
  margin: auto;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1344px;
  padding: 0 16px;
  margin: 72px auto 0;
  min-height: calc(100vh - 72px);
}

.navbar {
  display: flex;
  flex-direction: column;
}

.navbarOptions {
  display: flex;
  align-items: center;
}

.navbarInfo,
.navbarInfo a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.01px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 30px;
}

.navbarInfo .bold {
  font-weight: 600;
}

.navbarInfoNumber {
  padding: 2px 5px;
  height: 16px;
  min-width: 16px;
  text-align: center;
  background-color: #374e22;
  border-radius: 100%;
  position: relative;
  color: white;
  font-size: 8px;
  display: inline-block;
  top: -9px;
}

.navbarProfile {
  display: flex;
  flex-direction: column;
}

.navbarProfile a {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.21px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.navbarEmail {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.21px;
  text-align: right;
  color: rgba(0, 0, 0, 0.67);
  margin-bottom: 0;
}

.hamburgerButton {
  display: none;
}

.navbarMobileOptions {
  flex-direction: column;
  margin-top: 30px;
}

.navbarMobileOptions > div,
.navbarMobileOptions > a {
  margin: 20px;
  text-align: center;
}

.navbarMobileOptions .navbarProfile {
  margin-top: 10px;
}
.navbarMobileOptions .navbarProfile a {
  text-align: center;
  margin-top: 10px;
}

@media (min-width: 951px) {
  .navbarMobileOptions {
    display: none !important;
  }
}

@media (max-width: 950px) {
  .navbarOptions {
    display: none;
  }

  .navbarMobileOptions {
    display: flex;
  }

  .hamburgerButton {
    display: block;
    width: 30px;
    height: 30px;
    right: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
}


