.authenticationBoxContainer {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  max-width: 444px;
  border-radius: 8px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 30px;
  margin: 30px 0;
}

.authenticationLogo {
  margin-bottom: 30px;
  width: 140px;
  height: 59px;
}

.authenticationFormContainer {
  padding-top: 30px;
}

.inputContainer {
  margin-bottom: 25px;
  position: relative;
}

.inputContainer:first-of-type {
  margin-top: 25px;
}

.inputContainer label,
.inputContainer .label {
  display: block;
  margin-left: 15px;
  margin-bottom: 6px;
  font-size: 12px;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
}

.inputContainer .label {
  margin-bottom: 15px;
}

input:not([type="file"]),
input:not([type="checkbox"]),
textarea {
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.67);
  background-color: #ffffff;
  box-shadow: 0 4px 6px 0 #f5f5f5;
  width: 100%;
  font-size: 14px;
  display: block;
  padding: 8px 12px;
  padding-right: 39px;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.4;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.4;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0.4;
}
input:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.4;
}

textarea {
  resize: vertical;
}

.authorizationAdditionalLinks {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.08px;
}

.authorizationAdditionalLinks a {
  color: rgba(0, 0, 0, 0.87);
}

.authorizationAdditionalLinks .lightText {
  color: rgba(0, 0, 0, 0.67);
}

.authenticationHeader {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.15px;
  color: #263617;
  margin-bottom: 0;
}

.authenticationHeaderAdditional {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 12px;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
}

.mainAuthenticationContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0 16px;
}

.pageNotFound {
  width: 100%;
  text-align: center;
  margin-top: 80px;
  font-size: 32px;
}