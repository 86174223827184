.formRow .checkboxContainer {
	margin-left: 30px;
	margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
	font-weight: 300;
}

.formRow .checkboxContainer .checkboxLabel {
	min-width: 20px;
	cursor: pointer;
	position: relative;
	margin-right: 20px;
	align-self: flex-start;
}