.dashboardReferralContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dashboardRow {
  display: flex;
  background-color: white;
  border-radius: 8px;
} 

.dashboardMarginContainer {
  margin-top: 25px;
}

.dashboardRow > div {
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1.2;
}

.stdDashboardRowContainer {
  margin-bottom: 15px;
  display: flex;
  background-color: white;
  border-radius: 8px;
  flex-direction: column;
}

.dashboardStdRow {
  position: relative;
  cursor: pointer;
}

.dashboardStdWidth,
.dashboardAmountDue {
  justify-content: center;
  text-align: center;
}

.dashboardName,
.dashboardStdWidth,
.dashboardAmountDue {
  padding: 20px 10px 20px 10px;
  font-size: 14px;
  letter-spacing: 0.09px;
  color: rgba(0, 0, 0, 0.87);
}

.dashboardStdRow > div {
  padding-top: 50px;
  padding-bottom: 50px;
}

.dashboardStdRow .dashboardName {
  font-weight: 500;
}

.dashboardName {
  width: 10%;
}

.dashboardAmountDue {
  width: 8%;
}

.dashboardButtonMore {
  width: 8%;
}

.dashboardShowDetailsButton {
  min-width: unset;
  max-width: 100%;
  padding: 6px;
  margin: 0 auto;
}

.dashboardAmountDue {
  padding-right: 25px;
}

.dashboardStdWidth {
  width: 12%;
}

.dashboardName {
  padding-left: 45px;
}

.dashboardHead {
  background: transparent;
  margin-bottom: 15px;
}

.dashboardHead > div {
  color: rgba(0, 0, 0, 0.67);
}

.dashboardTooltip {
  position: absolute !important;
  left: calc(50% - 14px);
  top: -15px;
}

.dashboardAmountTooltip {
  left: calc(50% - 46px);
}

.progressIcon {
  background-color: #374e22;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 0px;
  justify-content: center;
  height: 16px;
  width: 16px;
  position: absolute;
  border-radius: 50%;
  z-index: 2;
}

.progressIcon::after {
  content: "\002713"
  
}

.inProgress {
  background-color: rgba(55, 78, 34, 0.67);
  padding-bottom: 6px;
}

.inProgress::after {
  content: "."
}

.pending {
  background-color: #c1c1c1;
  padding-bottom: 6px;
}

.pending::after {
  content: "..."
}

.dashboardProgressContainer::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  left: 50%;
  background-color: #747480;
  opacity: 0.2;
  display: block;
}

.dashboardGradientContainer::after {
  content: "";
  position: absolute;
  height: 15px;
  left: -50%;
  width: 100%;
  top: calc(50% - 7px);
  display: block;
}

.completedContainer::after {
  opacity: 0.41;
  background-color: #374e22;
}

.inProgressContainer::after {
  opacity: 0.41;
  background-image: linear-gradient(to left, rgba(163, 163, 163, 0.35), #374e22);
}

.pendingContainer::after {
  opacity: 0.41;
  background-color: rgba(163, 163, 163, 0.37);
}

.dashboardAdditionalInfo {
  display: none;
  padding: 14px 0;
  margin: 0px 75px 30px;
  border-top: 1px solid #e4e4e6;
}

.dashboardAdditionalInfo.showAdditionalInfo {
  display: flex;
}

.dashboardAdditionalInfo {
  font-size: 14px;
  position: relative;
}

.dashboardAdditionalInfo p {
  margin-bottom: 0px;
}

.dashboardAdditionalInfo .dashboardAdditionalValue {
  margin-bottom: 24px;
  font-weight: 500;
}

.dashboardAdditionalValue a {
  color: rgba(0, 0, 0, 0.87);
}

.dashboardAdditionalStd {
  width: 25%;
  padding-right: 10px;
}

.dashboardFilesContainer {
  width: 50%;
}

.dashboardFile {
  font-weight: 500px;
  width: 50%;
  display: inline-block;
  margin-bottom: 18px;
  font-weight: 500;
  cursor: pointer;
}

.dashboardFile {
  text-decoration: underline;
  color: #0000EE;
}

.dashboardFile:hover {
  color: #000000;
}

.fileIcon {
  width: 18px;
  height: 10px;
  margin-right: 7px;
}

.dahsboardHideDetailsContainer {
  padding: 8px;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 139px;
  height: 30px;
  border-radius: 8px;
  border: solid 2px #f4f4f5;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  right: 0;
  bottom: -31px;
  border-bottom: none;
  color: rgba(0, 0, 0, 0.87);
}

.upTickIcon {
  width: 12px;
  height: 8px;
  margin-left: 8px;
}

.dashboardLegend {
  margin: 30px 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  letter-spacing: 0.08px;
  color: rgba(0, 0, 0, 0.67);
}

.dashboardLegend .progressIcon {
  position: relative;
  margin-right: 15px;
}

.dashboardLegend > div {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  width: 100px;
}

div.dashboardMobileLabel {
  display: none;
}

.dashboardH1 {
  display: flex;
  align-items: center;
}

.newStatuses {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  width: 111px;
  height: 22px;
  border-radius: 12px;
  background-color: #0a84ff;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: -0.1px;
  color: #ffffff;
}

.newStatusesSmall {
  text-transform: uppercase;
  width: 40px;
  position: absolute;
  margin: 0;
  top: -35px;
}

.newDot {
  display: inline-block;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #0a84ff;
}

@media (max-width: 950px) {
  .dashboardName {
    padding-left: 20px;
  }

  .dashboardHead {
    display: none;
  }

  .dashboardMarginContainer {
    margin-top: 0;
  }

  .dashboardRow {
    flex-wrap: wrap;
  }

  div.dashboardMobileLabel {
    display: block;
  }

  .dashboardStdRow {
    padding: 0 60px;
  }

  div.dashboardName,
  div.dashboardAmountDue,
  div.dashboardStdWidth,
  div.dashboardMobileLabel {
    padding: 20px;
  }
  
  div.dashboardMobileLabel {
    width: calc(100% - 200px);
    font-size: 14px;
    font-weight: bold;
  }

  div.dashboardName,
  div.dashboardAmountDue,
  div.dashboardButtonMore,
  div.dashboardStdWidth {
    justify-content: center;
    width: 200px;
  }

  .dashboardProgressContainer::before {
    width: 0;
  }

  .inProgressContainer::after {
    opacity: 0.41;
    background-image: linear-gradient(to top, rgba(163, 163, 163, 0.35), #374e22);
  }  

  .dashboardGradientContainer::after {
    content: "";
    position: absolute;
    height: 100%;
    left: calc(50% - 7px);
    top: calc(-45% - 7px);
    display: block;
    width: 15px;
  }

  .dashboardAdditionalStd {
    width: 50%;
  }

  .dashboardFilesContainer {
    width: 100%;
  }

  .dashboardAdditionalInfo.showAdditionalInfo {
    flex-wrap: wrap;
  }
}

@media (max-width: 650px) {
  .dashboardAdditionalStd,
  .dashboardFile {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .dashboardStdRow {
    padding: 0;
  }

  .dashboardAdditionalInfo.showAdditionalInfo {
    margin: 0 20px 30px;
  }

  div.dashboardMobileLabel {
    width: calc(100% - 120px);
  }

  div.dashboardName,
  div.dashboardAmountDue,
  div.dashboardButtonMore,
  div.dashboardStdWidth {
    justify-content: center;
    width: 120px;
  }
}