.mainReferralContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.serviceTermSheetContainer h1,
.mainReferralDetailsContainer h1 {
  margin-top: 0px;
}

.serviceTermSheetContainer > p,
.mainReferralDetailsContainer > p {
  padding-top: 30px;
  margin-bottom: 3px;
}

.referralCol {
  width: calc(50% - 16px);
}

.referralCol:first-of-type {
  margin-right: 32px;
}

.serviceTermBox {
  background: white;
  padding: 22px 32px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.serviceTermBox > div {
  padding-left: 52px;
  padding-right: 52px;
}

.serviceTermUpper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f5;
  padding-bottom: 22px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.paymentTermAmountValue {
  font-weight: 500;
}

.serviceTermUpper > div {
  margin-bottom: 0;
}

.serviceTermLower {
  padding-top: 18px;
  display: flex;
}

.serviceTermLower ul {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
  margin-bottom: 0;
  padding-left: 15px;
}

.serviceTermTitle {
  width: 60%;
}

.serviceTermAmount {
  width: 28%;
  text-align: right;
}

.serviceTermAgreement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 12%;
  padding-left: 20px;
}

.serviceTermAgreement .checkboxContainer {
  margin-left: 10px;
}

.serviceTermHeader {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.15px;
  color: #263617;
  margin: 16px 0 24px;
}

.paymentTermTitle,
.serviceTermList {
  width: 80%;
}

.paymentTermAmount,
.paymentTermTotal {
  width: 20%;
  display: flex;
  flex-direction: column;
}

.paymentTermAmountLabel,
.paymentTermAmountValue {
  display: inline-block;
}

.paymentTermAmountLabel {
  font-size: 12px;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
  width: 50%;
  text-align: right;
  padding-right: 10px;
}

.paymentTermAmountValue {
  width: 50%;
  text-align: right;
}

.paymentTermUpper {
  position: relative;
}

.paymentTermUpper:after {
  content: "";
  position: absolute;
  height: 1px;
  right: 52px;
  width: 20%;
  bottom: -1px;
  background-color: rgba(0, 0, 0, 0.67);
}

.serviceTermsAgreementContainer {
  width: 100%;
  max-width: 420px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 22px;
}

.serviceTermsAgreementContainer .checkboxContainer {
  margin-left: 10px;
}

.serviceTermSumUpBox {
  text-align: right;
  margin-bottom: 40px;
}

.valueDescription {
  font-size: 12px;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
  margin-right: 5px;
}

.newReferralColContainer {
  display: flex;
}

.newReferralHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changeViewBox {
  background: white;
  padding: 10px;
  border-radius: 10px;
  display: flex;
}

.stdView {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 30px;
  cursor: pointer;
}

.stdView span {
  display: block;
  width: 100%;
  height: 3px;
  background: #000000;
  margin: 3px 0;
}

.boxView {
  display: flex;
  width: 30px;
  flex-direction: column;
  cursor: pointer;
}

.boxView span {
  display: block;
  width: 8px;
  height: 3px;
  margin: 3px 0;
  background: #B5B9AF;
  position: relative;
}

.boxView span::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 3px;
  background: #B5B9AF;
  position: absolute;
  top: 0;
  left: 11px;
}

.boxView span::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 3px;
  background: #B5B9AF;
  position: absolute;
  top: 0;
  left: 22px;
}

.boxViewActive .stdView span {
  background: #B5B9AF; 
}

.boxViewActive .boxView span {
  background: #000000; 
}

.boxViewActive .boxView span::before {
  background: #000000; 
}

.boxViewActive .boxView span::after {
  background: #000000; 
}

.boxViewSectionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.boxViewSectionContainer > div {
  max-width: calc(33% - 10px);
}

@media (max-width: 1023px) {
  .serviceTermBox > div {
    padding-left: 0px;
    padding-right: 0px;
  } 

  .paymentTermUpper:after {
    right: 0;
  }
}

@media (max-width: 950px) {
  .referralBox {
    padding: 20px;
  }
}

@media (max-width: 850px) {
  .referralBox {
    padding: 20px;
  }

  .serviceTermTitle,
  .serviceTermList,
  .paymentTermTotal,
  .paymentTermTitle,
  .paymentTermAmount {
    width: 50%;
  }

  .serviceTermAmount {
    width: 35%;
  }

  .serviceTermAgreement {
    width: 15%;
  }
}

@media (max-width: 750px) {
  .referralCol {
    width: 100%;
  }

  .referralCol:first-of-type {
    margin-right: 0;
  }

  .newReferralColContainer {
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .serviceTermUpper {
    flex-wrap: wrap;
  }

  div.serviceTermTitle {
    width: 100%;
    margin-bottom: 10px;
  }

  .serviceTermAmount {
    width: calc(100% - 110px);
  }

  .serviceTermAgreement {
    width: 110px;
  }

  .paymentTermTitle,
  .paymentTermAmount,
  .serviceTermList,
  .paymentTermTotal {
    width: 100%;
  }

  .paymentTermAmount {
    margin-top: 10px;
  }

  .serviceTermLower {
    flex-direction: column-reverse;
  }

  .serviceTermList {
    margin-top: 15px;
  }
}



