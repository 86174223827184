.errorComponent {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.07px;
  text-align: right;
  color: #e73e34;
  position: absolute;
  padding: 0 12px;
  right: 0;
  bottom: -20px;
}

.exclamation {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  top: -29px;
}

.errorDiv {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.07px;
  color: #e73e34;
}

.successDiv {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.07px;
  color: #00C851;
}
