.navbarContainer {
  width: 100%;
  max-width: 1312px;
  margin: auto;
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1344px;
  padding: 0 16px;
  margin: 72px auto 0;
  min-height: calc(100vh - 72px);
}

.navbar {
  display: flex;
  flex-direction: column;
}

.navbarOptions {
  display: flex;
  align-items: center;
}

.navbarInfo,
.navbarInfo a {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.01px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 30px;
}

.navbarInfo .bold {
  font-weight: 600;
}

.navbarInfoNumber {
  padding: 2px 5px;
  height: 16px;
  min-width: 16px;
  text-align: center;
  background-color: #374e22;
  border-radius: 100%;
  position: relative;
  color: white;
  font-size: 8px;
  display: inline-block;
  top: -9px;
}

.navbarProfile {
  display: flex;
  flex-direction: column;
}

.navbarProfile a {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.21px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.navbarEmail {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.21px;
  text-align: right;
  color: rgba(0, 0, 0, 0.67);
  margin-bottom: 0;
}

.hamburgerButton {
  display: none;
}

.navbarMobileOptions {
  flex-direction: column;
  margin-top: 30px;
}

.navbarMobileOptions > div,
.navbarMobileOptions > a {
  margin: 20px;
  text-align: center;
}

.navbarMobileOptions .navbarProfile {
  margin-top: 10px;
}
.navbarMobileOptions .navbarProfile a {
  text-align: center;
  margin-top: 10px;
}

@media (min-width: 951px) {
  .navbarMobileOptions {
    display: none !important;
  }
}

@media (max-width: 950px) {
  .navbarOptions {
    display: none;
  }

  .navbarMobileOptions {
    display: flex;
  }

  .hamburgerButton {
    display: block;
    width: 30px;
    height: 30px;
    right: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }
}

