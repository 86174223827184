label.radioContainer {
  margin-bottom: 10px;
  font-weight: 500;
  align-items: center;
  position: relative;
  display: flex;
  margin-left: 30px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 300;
}

.radioLabel {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 100%;
  border: solid 2px rgba(0, 0, 0, 0.67);
  display: inline-block;
  background: transparent;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  align-self: flex-start;
}

.radioChecked:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.67);
}

.radioImage {
  height: 23px;
  width: 29px;
  position: absolute;
  left: 0;
  top: 8px;
  left: 4px;
  display: none;
  cursor: pointer;
}

.radioChecked ~ .radioImage {
  display: block;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.radioContainer b {
  font-weight: 700;
}