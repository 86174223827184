.draftReferralContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 16px;
}

.draftReferralRow {
  display: flex;
  background-color: white;
  margin-bottom: 15px;
  border-radius: 8px;
}

.draftReferralRow > div {
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1.2;
}

.draftReferralDeleteIcon {
  height: 18px;
  width: 58px;
  cursor: pointer;
}

.draftReferralEditButton,
.draftReferralDelete {
  padding: 20px 30px;
}

.draftReferralStdRow .draftReferralName {
  font-weight: 500;
}

.draftReferralMarginContainer {
  margin-top: 25px;
}

.draftReferralName,
.draftReferralService,
.draftReferralServiceProvider,
.draftReferralEditDate {
  padding: 20px 20px 20px 0;
  font-size: 14px;
  letter-spacing: 0.09px;
  color: rgba(0, 0, 0, 0.87);
}

.draftReferralName,
.draftReferralServiceProvider {
  width: calc(33% - 70px);
}

.draftReferralService {
  width: calc(18% - 70px);
}

.draftReferralEditDate {
  width: calc(16% - 70px);
}

.draftReferralName {
  padding-left: 75px;
}

.draftReferralHead {
  background: transparent;
}

.draftReferralHead > div {
  color: rgba(0, 0, 0, 0.67);
}

div.draftReferralMobileLabel {
  display: none;
  width: 50%;
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .draftReferralName {
    padding: 15px;
  }
  .draftReferralEditButton, .draftReferralDelete {
    padding: 15px;
  }
  .draftReferralEditDate {
    width: 15%;
  }
}

@media (max-width: 750px) {
  .draftReferralName {
    width: 25%;
  }
  .draftReferralService {
    width: 15%;
  }
  .draftReferralServiceProvider {
    width: 35%;
  }
  .draftReferralEditDate {
    width: 25%;
  }

  .draftReferralRow {
    flex-wrap: wrap;
    justify-content: center;
  }

  .draftReferralHead .draftReferralEditButton,
  .draftReferralHead .draftReferralDelete {
    display: none;
  }
}

@media (max-width: 500px) {

  .draftReferralHead {
    display: none;
  }

  .draftReferralName,
  .draftReferralServiceProvider,
  .draftReferralService,
  .draftReferralEditDate,
  .draftReferralEditButton,
  .draftReferralDelete {
    width: 50%;
    padding: 15px;
  }

  div.draftReferralMobileLabel {
    display: block;
  }

  .draftReferralEditButton,
  .draftReferralDelete {
    justify-content: center;
  }

  .draftReferralMarginContainer {
    margin-top: 0;
  }
}