.mainFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.mainFormContainer h1 {
  margin-top: 0px;
  padding-right: 250px;
}

.mainFormContainer > p {
  padding-top: 30px;
  margin-bottom: 3px;
  padding-right: 250px;
}

.mainFormContainer .lastUpdate {
  position: absolute;
  right: 0;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.08px;
  text-align: right;
  color: rgba(0, 0, 0, 0.67);
  top: 46px;
}

.formUpdateBullet {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #0a84ff;
  margin-right: 10px;
}

.formBoxContainer {
  border-radius: 8px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-bottom: 74px;
  padding: 26px 72px;
}

.formBoxContainer h2 {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-bottom: 30px;
}

.formBoxContainer h2:not(:first-of-type) {
  margin-top: 30px;
}

.formRow {
  display: flex;
  justify-content: space-between;
}

.formCol {
  width: calc(50% - 15px);
  display: flex;
  justify-content: space-between;
}

.formCol .inputContainer {
  margin: 0 0 30px 0;
  width: calc(50% - 15px);
}

.formCol .inputContainer.inputWideContainer {
  width: 100%;
}

.dragAndDrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.67);
  border-style: dashed;
  margin-bottom: 55px;
  width: 100%;
  max-width: 550px;
  position: relative;
}

.dragAndDrop > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  width: 100%;
  max-width: 550px;
}

.dragAndDrop .formUploadedFilesContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: solid 1px rgba(0, 0, 0, 0.67);
  border-top-style: dashed;
}

.formUploadedFilesContainer p {
  margin-bottom: 0;
  font-size: 14px;
}

.dragAndDrop img {
  height: 22px;
  width: 32px;
  margin-bottom: 10px;
}

.uploadingFile {
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background-color: lightgray;
}

.inputFileButton {
  cursor: pointer;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  max-width: 250px;
  border-radius: 17px;
  border: solid 1px rgba(0, 0, 0, 0.67);
  padding: 11px;
}

.dragAndDrop .dropInfo {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: rgba(0, 0, 0, 0.37);
  margin-bottom: 0;
}

.fileList {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 12px;
}

.inputContainer.additionalContainer {
  margin-left: 68px;
  width: 100%;
  max-width: 480px;
}

.inputContainer.additionalContainerNoLm {
  margin-left: 0;
}

.inputContainer.fullWidthInputContainer {
  width: 100%;
}

.submitFormContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
}

.requiredInfo {
  font-size: 12px;
  letter-spacing: 0.07px;
  color: rgba(0, 0, 0, 0.67);
}

.submitFormContainer .btn {
  min-width: 250px;
  padding: 10px 30px;
}

.medFormColQuestions {
  flex-direction: column;
  width: 100%;
}

.medFormColQuestions .inputWideContainer{
  margin-bottom: 4px;
} 

.deleteFile {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  transform: rotate(45deg);
  font-size: 18px;
  color: #DE473E;
  cursor: pointer;
  position: relative;
  top: 1px;
}

@media (max-width: 1023px) {
  .formRow {
    flex-wrap: wrap;
  }

  .formCol {
    width: 100%;
  }
}

@media (max-width: 700px) {

  .formCol {
    flex-wrap: wrap;
  }

  .formCol .inputContainer {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .formBoxContainer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .formBoxContainer {
    position: relative;
  }

  .submitFormContainer {
    flex-direction: column;
  }

  .mainFormContainer h1 {
    padding-right: 0;
  }

  .mainFormContainer .lastUpdate {
    top: -25px;
    right: 10px;
  }

  .requiredInfo {
    position: relative;
    top: -15px;
  }
}